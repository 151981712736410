import axios from "axios";
import { formatDate, formatDateUtc } from "../../utils/dateUtils";
import { getRowsFromWindowSize } from "../../utils";
import config from "../../config";

export * from "./client";
export * from "./admin";

const BASE_URL_API = `${config.API_URL}/api/v2/app`;

export const getAdSpacesTypes = async () => axios.get(`${BASE_URL_API}/adspaces/types`);

export const getAdSpaceById = async (id) => axios.get(`${BASE_URL_API}/adspaces/${id}`);

export const getAdSpaceUnavailableDateRanges = async (id) =>
  axios.get(`${BASE_URL_API}/adspaces/${id}/unavailabledateranges`);

export const getAdSpaces = async ({ asset, filters = {}, sort = {} }) => {
  const sortedParams = sort && sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : ``;

  let stringFilters = "";
  if (filters.start) {
    stringFilters += `&start=${filters.start}`;
  }
  if (filters.end && !filters.long_term) {
    stringFilters += `&end=${filters.end}`;
  }
  if (filters.long_term && filters.start) stringFilters += "&long_term=true";
  if (filters.tag) stringFilters += `&tag=${filters.tag}`;

  return axios.get(`${BASE_URL_API}/assets/${asset}/adspaces?${stringFilters}${sortedParams}`);
};

export const getSortKeyForAssets = (name) => {
  switch (name) {
    case "city":
      return "city.name";

    case "country":
      return "city.country.name";

    case "taxId":
      return "tax_id";
    default:
      return name;
  }
};

export const getAssets = async ({ regions = "", numberPerPage = getRowsFromWindowSize(), page = "", sort = "" }) => {
  const sortedParams = sort && sort.name ? `&orderBy=${getSortKeyForAssets(sort.name)}&orderDir=${sort.direction}` : ``;
  return axios.get(`${BASE_URL_API}/assets?page_size=${numberPerPage}&page=${page}&regions=${regions}${sortedParams}`);
};

export const getCitiesAreas = async ({ sort = { name: "name", direction: "asc" } }) => {
  const sortedParams = sort && sort.name ? `orderBy=${getSortKeyForAssets(sort.name)}&orderDir=${sort.direction}` : ``;
  return axios.get(`${BASE_URL_API}/assets?${sortedParams}`);
};

export const getSpaces = async (assetId, numberPerPage, pageNumber, filters = {}) => {
  let stringFilters = "";
  if (filters.start) stringFilters += `&start=${formatDate(filters.start, "YYYY-MM-DD")}`;
  if (filters.end && !filters.long_term) stringFilters += `&end=${formatDate(filters.end, "YYYY-MM-DD")}`;
  if (filters.long_term && filters.start) stringFilters += "&long_term=true";
  if (filters.priceStart) stringFilters += `&min_price=${filters.priceStart}`;
  if (filters.priceEnd) stringFilters += `&max_price=${filters.priceEnd}`;
  if (filters.sizeStart) stringFilters += `&min_size=${filters.sizeStart}`;
  if (filters.sizeEnd) stringFilters += `&max_size=${filters.sizeEnd}`;
  if (filters.types) stringFilters += `&types=${filters.types.map((t) => t.name).join(",")}`;
  if (filters.features) stringFilters += `&features=${filters.features.map((f) => f.name).join(",")}`;
  if (filters.uses) stringFilters += `&usenames=${filters.uses.map((u) => u.name).join(",")}`;
  if (filters.spot) stringFilters += `&tag=${filters.spot}`;

  return axios.get(
    `${BASE_URL_API}/assets/${assetId}/spaces?page_size=${numberPerPage}&page=${pageNumber}${stringFilters}`,
  );
};

export const getBookingCategories = () => axios.get(`${BASE_URL_API}/bookings/categories/`);

export const registerCompany = async ({
  name,
  commercialName,
  thumbUrl,
  taxId,
  countryId,
  isAgency,
  billingAddress,
  serviceAddress,
}) => {
  return axios.post(`${BASE_URL_API}/companies`, {
    name,
    commercial_name: commercialName,
    thumb_url: thumbUrl,
    tax_id: taxId,
    country_id: countryId,
    is_agency: isAgency,
    billing_address: billingAddress || null,
    service_address: serviceAddress || null,
  });
};

export const getCompanyByTaxId = async (taxId) => axios.get(`${BASE_URL_API}/companies/find?tax=${taxId}`);

export const getCountries = async () =>
  axios.get(`${BASE_URL_API}/countries`, {
    headers: { "Content-Type": "application/json" },
  });

export const getFeatureFlags = async () => axios.get(`${BASE_URL_API}/featureflags`);

export const getRegions = () => axios.get(`${BASE_URL_API}/regions`);

export const getRegion = (regionId) => axios.get(`${BASE_URL_API}/regions/${regionId}`);

export const getSettings = async () => axios.get(`${BASE_URL_API}/settings`);

export const getSpaceFeatures = async (asset = null) => {
  const query = asset ? `&asset=${asset}` : "";
  return axios.get(`${BASE_URL_API}/spaces/features?page_size=9999${query}`);
};

export const getSpaceTypes = async () => axios.get(`${BASE_URL_API}/spaces/types`);

export const getSpaceSubTypes = async () => axios.get(`${BASE_URL_API}/spaces/subtypes`);

export const getSpace = async (id) => axios.get(`${BASE_URL_API}/spaces/${id}`);

export const getPriceByDateRange = async ({ id, start, end, longTerm }) => {
  let stringFilters = "";

  if (end && !longTerm) stringFilters += `&end=${formatDateUtc(end, "YYYY-MM-DD")}`;
  if (longTerm && start) stringFilters += "&long_term=true";

  return axios.get(`${BASE_URL_API}/spaces/${id}/price?start=${formatDateUtc(start, "YYYY-MM-DD")}${stringFilters}`);
};

export const getSpaceUnavailableDateRanges = async (id) => axios.get(`${BASE_URL_API}/spaces/${id}/unavailability`);

export const getSpotsByAsset = (assetId) => axios.get(`${BASE_URL_API}/spots/byasset/${assetId}`);

// User should be logged in

export const getAssetSpaceActivities = (assetId) => axios.get(`${BASE_URL_API}/assets/${assetId}/spaces/activity`);

export const getContractModels = async (asset) => axios.get(`${BASE_URL_API}/assets/${asset}/contracts/templates`);

export const getRatingCategories = () => axios.get(`${BASE_URL_API}/bookings/rating/categories`);
