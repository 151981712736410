const adapterInvoice = (res) => ({
  agent_id: -1,
  asset_name: res.asset_data?.name,
  attachment_url: "",
  booking_author: -1,
  booking_category_id: res?.booking_category_id,
  booking_category_slug: res?.booking_category_slug,
  booking_id: res?.booking_id,
  brand_name: res?.booking_data?.brand_name,
  company_name: res?.company_data?.name,
  concept: -1,
  confirmed_at: -1,
  created_at: res?.created_at,
  date: res?.date,
  doc_type: res?.net_total < 0 ? "credit_note" : "invoice", // check this value, ver modal de facturacion.
  duration: -1,
  id: res?.id,
  invalid_at: -1,
  meta_data: {
    asset_id: res?.asset_id,
    asset_name: res?.asset_data?.name,
    brand_name: res?.booking_data?.brand_name,
    company_id: res?.company_id,
    company_name: res?.company_data?.name,
    end: res?.booking_data?.end,
    project_name: res?.booking_data?.project_name,
    space_name: -1,
    start: res?.booking_data?.start,
    user_id: -1,
    user_name: -1,
  },
  payment: -1,
  payment_method: -1,
  price: res?.net_total,
  receipt_url: "",
  space_id: "",
  tax_id: res?.company_data?.tax_id,
  updated_at: res?.updated_at,
  number: res?.number,
});
const adapterInvoiceFlag = (invoices) => {
  const newInvoices = [];
  invoices.forEach((element) => {
    const adapter = adapterInvoice(element);
    newInvoices.push(adapter);
  });
  return newInvoices;
};

const adapterMRI = (asset) => ({
  bank_account_name: asset.invoices_data?.bank_account_name,
  bank_account_number: asset.invoices_data?.bank_account_number,
  bank_sort_code: asset.invoices_data?.bank_sort_code,
  bank_swift: asset.invoices_data?.bank_swift,
  bank_iban: asset.invoices_data?.bank_iban,
  additional_note: asset.invoices_data?.additional_note,
  billing_data: asset.invoices_data?.billing_data,
  due_in_days: asset.invoices_data?.due_in_days,
  email: asset.invoices_data?.email,
  biller_code: asset.invoices_data?.biller_code,
  bank_check: asset.invoices_data?.bank_check,
  module: "mri",
});
const adapterBAC = (asset) => ({
  bank_account_name: asset.invoices_data?.bank_account_name,
  bank_account_number: asset.invoices_data?.bank_account_number,
  bank_sort_code: asset.invoices_data?.bank_sort_code,
  bank_swift: asset.invoices_data?.bank_swift,
  bank_iban: asset.invoices_data?.bank_iban,
  additional_note: asset.invoices_data?.additional_note,
  billing_data: asset.invoices_data?.billing_data,
  taxes: asset?.city?.country?.taxes || [],
  due_in_days: asset.invoices_data?.due_in_days ? asset.invoices_data?.due_in_days : asset.invoices_data?.due_days,
  module: "bac",
});
const adapterInvoiceDataByModule = (asset) => {
  let invoiceData = {};
  switch (asset.module) {
    case "mri":
      invoiceData = adapterMRI(asset);
      break;

    case "bac":
    default:
      invoiceData = adapterBAC(asset);
      break;
  }
  return invoiceData;
};

const adapterMRIFromForm = (invoiceData) => ({
  bank_account_name: invoiceData.bank_account_name,
  bank_iban: invoiceData.iban,
  bank_sort_code: Number(invoiceData.sort_code),
  module: invoiceData.module,
  billing_address: invoiceData.billing_address,
  bank_account_number: invoiceData.bank_account_number,
  bank_swift: invoiceData.bank_swift,
  asset_address: invoiceData.asset_address,
  due_in_days: Number(invoiceData.due_in_days),
  email: invoiceData?.email,
  biller_code: invoiceData?.biller_code,
  bank_check: invoiceData?.bank_check,
});
const adapterBACFromForm = (invoiceData) => ({
  billing_address: invoiceData.billing_address,
  bank_account_name: invoiceData.bank_account_name,
  bank_account_number: invoiceData.bank_account_number,
  bank_iban: invoiceData.bank_iban,
  bank_sort_code: invoiceData.bank_sort_code,
  bank_swift: invoiceData.bank_swift,
  taxes: invoiceData?.taxes || [],
  due_in_days: invoiceData.due_in_days,
  module: "bac",
});
const adapterInvoiceDataFromForm = (invoice) => {
  let invoiceData = {};
  switch (invoice.module) {
    case "mri":
      invoiceData = adapterMRIFromForm(invoice);
      break;

    case "bac":
    default:
      invoiceData = adapterBACFromForm(invoice);
      break;
  }
  return invoiceData;
};
export { adapterInvoiceFlag, adapterInvoiceDataByModule, adapterInvoiceDataFromForm };
