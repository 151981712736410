
  import t0 from "./theme-client";
  import t25 from "./theme.asset-25.js";
import t26 from "./theme.asset-26.js";
import t27 from "./theme.asset-27.js";


  export const themes = {  25: t25,
 26: t26,
 27: t27,
 }

  export const getThemeById = (id) => id && themes[id] ? themes[id] : t0

  export default t0;
