export default {
  API_URL: "https://api-ingkapl-prod.icentresbooking.com",
    API_SERVICE_URL: "https://services.bookacorner.io",
  CLOUDFRONT_URL: "https://files.bookacorner.net",
  CLIENT: "ingkapl",
  GOOGLE_MAPS_API_KEY: "AIzaSyDSi0v-JQuubJRup1luQrF1Q-oiUeXpbXE",
  HOME_URL: "https://pl.icentresbooking.com",
  UPLOAD_URL: "https://saxtyc5ci8.execute-api.us-east-1.amazonaws.com/prod",
  LOGGLY_KEY: "b523b1f3-6dd4-4be4-a10f-017f039f4de5",
  PROFORMA_URL: "https://bac-mediafiles.s3.amazonaws.com/all/proforma-cbre.html",
  HOME_HEADER_IMG_URL: "https://files.bookacorner.net/all/media/Fotos+de+portada+para+HOME/Foto+Centro+1.jpg",
  HOME_COMMENT_TEXT: "THE IMPLEMENTATION OF STAGING HAS BEEN A REVOLUTION RETAILERS AND OWNERS IN COMMERCIAL REAL ESTATE",
  HOME_COMMENT_AUTHOR: "Mr Staging",
  HOME_COMMENT_LINK: "https://bookacorner.com",
  HOME_COMMENT_IMAGE: "https://files.bookacorner.net/all/media/BAC/foto_branding.png",
  LOGO_LIGHT: "https://files.bookacorner.net/all/media/Ingka/INGKA-Dark-Logo.png",
  LOGO_DARK: "https://files.bookacorner.net/all/media/Ingka/INGka_Centers.png",
  ADMIN_DEFAULT_LOCALE: "pl-ingka",
  TENANT_DEFAULT_LOCALE: "pl-ingka",
  ABOUT_US: "",
  HELP: "",
  TERMS_AND_CONDITIONS: "https://bookacorner.com/condiciones-generales",
  PRIVACY_POLICY: "https://files.bookacorner.net/all/media/Privacy Policy/INGKA Privacy Policy.pdf",
  SOCIAL_LINKS: {"facebook":"https://facebook.com","twitter":"twitter.com","instagram":"instagram.com"},
  ADVERTISING_IMG: "https://files.bookacorner.net/all/media/912de3e4-b8bc-4a9e-bbf4-f3c0d5ffb6ef.jpg",
  REVERB_APP_KEY: "p6KQ7LAqoITwfmW6Jqa3viAU",
  REVERB_HOST: "api-ingkapl-prod.icentresbooking.com",
  REVERB_AUTH_ENDPOINT: "https://api-ingkapl-prod.icentresbooking.com/api/broadcasting/auth",
  REVERB_PORT: 6001,
  FONT_API_URL: "https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&family=Raleway:wght@400;500;600;700&display=swap",
  LOCALES_SUPPORTED_ARRAY: ["es","en","fr","pt","it","pl","en-ingka","pl-ingka","es-cbreau","en-cbreau","pt-cbreau","fr-cbreau","it-cbreau"],
  MAPBOX_API_KEY: "pk.eyJ1IjoiYWxleGlzcGFndXJhIiwiYSI6ImNscDBnM2lvaTA4ZzEyaW1kcG83MHV5OTIifQ.oOPsNiopJzxHHDUyERjMgw",
  HOME_MAP_STYLE: "mapbox://styles/alexispagura/clpic53ft00bh01qr9e90d855",
  HOME_MAP_BOUNDS: [[13.96609566680425,48.674974972355585],[26.211035188816197,55.10566563857529]],
  MAPBOX_MARKER: "https://files.bookacorner.net/all/markers/mapbox-icon.png",
    PDF_WORKER: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.12.313/build/pdf.worker.min.js",
  USER_MANUAL: "https://files.bookacorner.net/all/media/Ingka/BAC-User-Manual.pdf",
  COGNITO_URL: "",
}
